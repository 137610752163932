:root {
  --voting-cards-weight-0: #8b898b;
  --voting-cards-weight-1: #f44336;
  --voting-cards-weight-2: #ffc107;
  --voting-cards-weight-3: #4caf50;
}

.voting-voting_cards {
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #d9d9d9;
}
.voting-voting_cards.current-choice {
  text-align: center;
  margin-bottom: 0;
  border-bottom: none;
}
.voting-voting_cards.current-choice .vote-card {
  margin-top: 0.5rem;
}
.voting-voting_cards.current-choice .vote-card .vote-label {
  margin-bottom: 1rem;
  @apply text-black;
}
.voting-voting_cards.current-choice .change-vote-button:hover {
  font-size: 1rem;
  margin-top: 0;
}
.voting-voting_cards.loading {
  opacity: 0.3;
  pointer-events: none;
  position: relative;
  @apply relative cursor-wait;
}
.voting-voting_cards.loading::before {
  @apply content-[""] block w-6 h-6 rounded-full animate-spin border-4 border-l-background border-y-background border-r-secondary;
  z-index: 1000;
  vertical-align: middle;
  position: absolute;
  top: calc(50% - 12.5px);
  left: calc(50% - 12.5px);
}
.voting-voting_cards .vote-title {
  text-align: center;
  font-size: 1.5rem;
}
.voting-voting_cards .votes_counter {
  letter-spacing: 0.2rem;
  font-size: 1rem;
}
.voting-voting_cards .vote-link.hidden {
  display: none;
}
.voting-voting_cards .vote-card {
  display: inline-block;
}
.voting-voting_cards .vote-card .vote-label {
  font-size: 1rem;
  margin-bottom: 0;
}
.voting-voting_cards .vote-card > svg {
  display: inline-block;
  width: 3rem;
  height: 6rem;
  vertical-align: middle;
  margin: -0.5rem 0 0 0.5rem;
  max-width: 100%;
  transition: transform 150ms ease-in-out;
}
.voting-voting_cards .vote-card > svg:hover {
  transform: scale(1.15) rotate(-5deg);
}
.voting-voting_cards .vote-card.weight_3 {
  color: var(--voting-cards-weight-3);
}
.voting-voting_cards .vote-card.weight_3 > svg {
  fill: var(--voting-cards-weight-3);
}
.voting-voting_cards .vote-card.weight_2 {
  color: var(--voting-cards-weight-2);
}
.voting-voting_cards .vote-card.weight_2 > svg {
  fill: var(--voting-cards-weight-2);
}
.voting-voting_cards .vote-card.weight_1 {
  color: var(--voting-cards-weight-1);
}
.voting-voting_cards .vote-card.weight_1 > svg {
  fill: var(--voting-cards-weight-1);
}
.voting-voting_cards .vote-card.voted > svg {
  transform: scale(1.15) rotate(-5deg);
}
.voting-voting_cards .voting-button {
  background: none;
  border: none;
  color: #fff;
  font-weight: bold;
}
.voting-voting_cards .dim {
  opacity: 0.3;
  pointer-events: none;
}
.voting-voting_cards .disabled {
  pointer-events: none;
}
.voting-voting_cards .vote-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}
.voting-voting_cards .vote-block .vote-count {
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 0.5rem;
}
.voting-voting_cards .change-vote-button {
  transition: all 150ms ease-in-out;
}
.voting-voting_cards .change-vote-button:hover {
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 0.1rem;
}
.voting-voting_cards .button.abstain-button {
  text-transform: uppercase;
  color: var(--voting-cards-weight-0);
  border-color: var(--voting-cards-weight-0);
  background-color: transparent;
}
.voting-voting_cards .button.abstain-button.voted {
  background-color: var(--voting-cards-weight-0);
  color: #fff;
  border-color: transparent;
}
.voting-voting_cards .button.abstain-button.voted.disabled {
  opacity: 1;
}

.vote_proposal_modal div.button__sm {
  display: inline-block;
}
.vote_proposal_modal .vote-label {
  @apply ml-2;
}

.card__list-metadata .voting-weight_3 {
  color: var(--voting-cards-weight-3);
}
.card__list-metadata .voting-weight_2 {
  color: var(--voting-cards-weight-2);
}
.card__list-metadata .voting-weight_1 {
  color: var(--voting-cards-weight-1);
}
.card__list-metadata .voting-weight_0 {
  color: var(--voting-cards-weight-0);
}

.loading-spinner {
  @apply relative cursor-wait;
}
.loading-spinner::before {
  @apply content-[""] ml-[50%] md:ml-[calc(50%-0.75rem)] block w-6 h-6 rounded-full animate-spin border-4 border-l-background border-y-background border-r-secondary z-20;
}
.loading-spinner.alert::before {
  @apply border-r-alert;
}
.loading-spinner.warning::before {
  @apply border-r-warning;
}
.loading-spinner.primary::before {
  @apply border-r-primary;
}

.awesome_autosave-notice {
  background-color: rgb(var(--success-rgb)/90%);
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em 2em;
  border-radius: 4px;
  border: 1px solid var(--success);
  font-weight: 600;
  color: white;
  z-index: 1000;
}
.awesome_autosave-notice.error {
  background-color: rgb(var(--alert-rgb)/90%);
}

.decidim_awesome-custom_fields.private_body {
  @apply px-4 py-6 mt-8 border border-gray rounded-s rounded-ee bg-gray-5 text-black font-normal;
}
.decidim_awesome-custom_fields .private_body-disclosure {
  @apply -mt-4 mb-8 text-lg italic;
}
.decidim_awesome-custom_fields .private_body-disclosure svg {
  @apply inline-block w-6 h-6 mr-2 align-bottom;
}
.decidim_awesome-custom_fields dt {
  @apply font-sans font-semibold;
}
.decidim_awesome-custom_fields dd {
  @apply px-0;
}
.decidim_awesome-custom_fields .proposal_custom_field h1 {
  @apply font-sans font-bold text-4xl mt-4;
}
.decidim_awesome-custom_fields .proposal_custom_field h2 {
  @apply font-sans font-bold text-3xl mt-4;
}
.decidim_awesome-custom_fields .proposal_custom_field h3 {
  @apply font-sans font-semibold text-2xl mt-4;
}
.decidim_awesome-custom_fields .proposal_custom_field h4 {
  @apply font-sans font-semibold text-xl mt-4;
}
.decidim_awesome-custom_fields .proposal_custom_field h5 {
  @apply font-sans font-semibold text-lg mt-4;
}
.decidim_awesome-custom_fields .proposal_custom_field h6 {
  @apply font-sans font-semibold text-md mt-4;
}
.decidim_awesome-custom_fields .proposal_custom_field p {
  @apply mt-4;
}
.decidim_awesome-custom_fields .proposal_custom_field label .tooltip-element {
  @apply m-0 p-0 invisible block;
}
.decidim_awesome-custom_fields .proposal_custom_field label .tooltip-element::before {
  @apply visible text-xs text-gray-2 italic font-thin;
  content: attr(tooltip);
}
.decidim_awesome-custom_fields .proposal_custom_field .form-group:not(:first-of-type) {
  @apply mt-6;
}
.decidim_awesome-custom_fields .proposal_custom_field .form-control {
  @apply w-full block mt-4;
  /* read-only can be applied only to input and textarea, otherwise selects will be set as readonly */
}
.decidim_awesome-custom_fields .proposal_custom_field .form-control:not(.reset-defaults) {
  @apply inline-block px-4 py-2 border border-gray outline outline-1 outline-transparent rounded bg-background-2 text-black font-normal placeholder:text-gray focus:outline-2 focus:outline-secondary disabled:bg-background-3 disabled:text-gray-2 disabled:cursor-not-allowed;
}
.decidim_awesome-custom_fields .proposal_custom_field .form-control:not(.reset-defaults).sm {
  @apply px-1 py-0.5 text-sm;
}
.decidim_awesome-custom_fields .proposal_custom_field .form-control:not(.reset-defaults).sm-icon {
  @apply pr-1 pl-6 py-0.5 text-sm;
}
.decidim_awesome-custom_fields .proposal_custom_field .form-control:not(.reset-defaults).is-invalid-input:not(:focus) {
  @apply outline-2 outline-alert;
}
.decidim_awesome-custom_fields .proposal_custom_field .form-control:not(select) {
  @apply read-only:bg-background read-only:border-0 read-only:text-gray-2;
}
.decidim_awesome-custom_fields .proposal_custom_field .formbuilder-checkbox > label {
  @apply font-normal;
}
.decidim_awesome-custom_fields .proposal_custom_field .formbuilder-autocomplete {
  @apply relative;
}
.decidim_awesome-custom_fields .proposal_custom_field .formbuilder-autocomplete .formbuilder-autocomplete-list {
  @apply z-20 max-h-52 outline outline-1 outline-transparent rounded border border-gray text-black font-normal;
  display: none;
}
.decidim_awesome-custom_fields .proposal_custom_field .formbuilder-autocomplete .formbuilder-autocomplete-list li {
  @apply cursor-pointer rounded px-4 py-2 bg-background-2;
}
.decidim_awesome-custom_fields .proposal_custom_field .formbuilder-autocomplete .formbuilder-autocomplete-list li.active-option {
  @apply bg-background;
}

#LimitAmendmentsModal .instructions {
  @apply grid gap-4 prose-a:text-secondary;
}